import './App.css';
import videoSrc from './assets/mixkit-clouds-in-the-sky-traveling-slowly-with-the-wind-21575.mp4';
import imgSrc from './assets/portrait.png';

import { cx } from '@emotion/css';
import {
  videoBackground,
  content,
  img,
  text,
  heading,
  tagLine,
  links,
  linkItem
} from './style';

import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  return (
    <div>
      <video className={videoBackground} autoPlay loop muted>
        <source src={videoSrc} type='video/mp4' />
      </video>
      <header className={content}>
        <img src={imgSrc} alt="Jeff Sinclair" className={img} />
        <h1 className={cx(text, heading)}>Jeff Sinclair</h1>
        <p className={cx(text, tagLine)}>Technology Leader &nbsp;&bull;&nbsp; Software Engineer &nbsp;&bull;&nbsp; Gamer</p>
        <nav className={links}>
          <a href="https://resume.jeffable.com" target="_blank" rel="noreferrer" className={linkItem}>
            <FontAwesomeIcon icon={faBriefcase} />
          </a>
          <a href="https://www.linkedin.com/in/jefferysinclair/" target="_blank" rel="noreferrer" className={linkItem}>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://github.com/spicypenguin" target="_blank" rel="noreferrer" className={linkItem}>
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </nav>
      </header>
    </div >
  );
}

export default App;
