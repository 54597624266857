import { css } from '@emotion/css';

export const videoBackground = css`
    height: 100%;
    float: left;
    padding: 0;
    top: 0;
    position: fixed;
`;

export const content = css`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const links = css`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const linkItem = css`
    padding: 5px;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    line-height: 100px;
    color: white;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    border-radius: 4px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;


    :hover {
        background-color: rgba(255, 255, 255, 0.175);
        color: #fff;
        font-size: 3.25em;   
    }
`;

export const img = css`
    height: 200px;
    margin-bottom: 20px;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;

    :hover {
        height: 250px;
        transform: rotate(360deg);
    }
`;

export const text = css`
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
`;

export const heading = css`
    font-size: 4.35em;
    font-weight: 900;
    letter-spacing: -0.035em;
    line-height: 1em;
`;

export const tagLine = css`
    font-size: 1.25em;
    margin: 0.75em 0 0.25em 0;
    opacity: 0.75;
`;